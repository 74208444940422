import React, { useEffect, useState } from 'react'

import OutboundLink from './block-content/outboundLink'
import styles from './cookieBanner.module.css'
import { small } from './typography.module.css'

const CookieBanner = () => {
  const [showCookieBanner, setShowCookieBanner] = useState(false)

  useEffect(() => {
    const cookiesAcceptedCookie = document.cookie.split('; ').find(row => row.startsWith('cookies_accepted='))
    const cookiesAcceptedCookieValue = cookiesAcceptedCookie && cookiesAcceptedCookie.split('=')[1]

    const timeoutId = window.setTimeout(() => {
      setShowCookieBanner(cookiesAcceptedCookieValue === undefined ||
        cookiesAcceptedCookieValue !== '1')
    }, 1000)

    return () => {
      window.clearTimeout(timeoutId)
    }
  }, [])

  const onBannerButtonClick = () => {
    document.cookie = 'cookies_accepted=1;path=/;samesite=lax;max-age=31536000;secure' // store for 1 year
    setShowCookieBanner(false)
  }

  return (
    <>
      {showCookieBanner &&
        <div className={styles.cookieBanner}>
          <p className={small}>This website uses cookies to enhance the experience. By continuing to use this website you agree to the <OutboundLink target='_blank' href='https://www.termsfeed.com/live/c13e4b73-4f12-4ccd-bda3-bb3bcb729746'>Privacy Policy</OutboundLink>.</p>
          <button className={small} onClick={onBannerButtonClick}>Got it</button>
        </div>}
    </>
  )
}

export default CookieBanner

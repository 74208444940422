module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Harry Theo","short_name":"HarryTheo","description":"A Portfolio Website with articles, projects and tips by Harry Theo.","lang":"en-GB","start_url":"/?utm_source=pwa","background_color":"#455A64","theme_color":"#3f1dcb","display":"standalone","icon":"src/favicons/ht.transparent.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dd1e261cb368f0c7d2fb7169d1705aaf"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-WK9RZ3KGM0","head":false,"anonymize":true,"defer":true,"respectDNT":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"swDest":"./public/service-worker.js","offlineGoogleAnalytics":true,"skipWaiting":true,"clientsClaim":true,"cleanupOutdatedCaches":true,"navigationPreload":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
